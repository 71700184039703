<template>
  <div class="content_body order_create" v-loading="loading">
    <el-row class="border_bottom">
      <el-col :span="9" class="pad_15 border_right">
        <el-autocomplete
          popper-class="customer-autocomplete"
          prefix-icon="el-icon-user-solid"
          v-model="customerName"
          style="width: 100%"
          size="small"
          placeholder="请输入客户姓名、手机号、编号查找，无匹配按回车新增"
          :fetch-suggestions="saleCustomerData"
          @select="handleCustomerSelect"
          :disabled="customerID != null"
          :trigger-on-focus="false"
          :hide-loading="false"
          :highlight-first-item="true"
          :select-when-unmatched="true"
        >
          <template slot="append">
            <div class="position_relative">
              <el-button
                icon="el-icon-delete"
                @click="removeCustomer"
              ></el-button>
              <el-button
                v-show="customerID != null"
                type="text"
                class="detailBtn position_absolute"
                @click="customerDetailClick"
                >详情</el-button
              >
            </div>
          </template>
          <template slot-scope="{ item }">
            <div class="name">
              {{ item.Name }}
              <el-tag size="mini" v-if="item.CustomerLevelName">{{
                item.CustomerLevelName
              }}</el-tag>
            </div>
            <div class="info">手机号：{{ item.PhoneNumber }}</div>
            <span class="info" v-if="item.Code">客户编号：{{ item.Code }}</span>
          </template>
        </el-autocomplete>
      </el-col>
      <el-col :span="15" class="pad_15">
        <el-col :span="12" style="white-space: nowrap">
          <el-form :inline="true" size="small">
            <el-form-item style="margin-bottom: 0px; height: 32px">
              <div v-if="isReplacementOrder">
                <span slot="label">
                  补单
                  <el-popover placement="bottom-start" trigger="hover">
                    <p>1.补单多用于补开历史订单。</p>
                    <p>2.使用补单后该笔订单的相关营收将计入补录的时间。</p>
                    <el-button
                      type="text"
                      style="color: #dcdfe6"
                      icon="el-icon-info"
                      slot="reference"
                    ></el-button>
                  </el-popover>
                </span>
                <el-switch
                  v-model="IsSupplement"
                  @change="isSupplementChange"
                ></el-switch>
              </div>
            </el-form-item>
            <el-form-item
              style="margin-bottom: 0px; height: 32px"
              v-show="IsSupplement"
            >
              <el-date-picker
                v-model="BillDate"
                type="datetime"
                placeholder="选择日期时间"
                value-format="yyyy-MM-dd HH:mm:ss"
                :picker-options="pickerOptions"
                default-time="9:30:00"
                @change="dateChange"
              ></el-date-picker>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12" class="text_right" style="white-space: nowrap">
          <el-button
            v-if="isAgileSell"
            type="primary"
            size="small"
            :plain="!(typeIndex == 0)"
            @click="typeChange(0)"
            >开单</el-button
          >
          <el-button
            type="primary"
            size="small"
            :plain="!(typeIndex == 1)"
            @click="typeChange(1)"
            >开卡</el-button
          >
          <el-button
            type="primary"
            size="small"
            :plain="!(typeIndex == 2)"
            @click="typeChange(2)"
            >消耗</el-button
          >
          <el-button
            type="primary"
            size="small"
            :plain="!(typeIndex == 3)"
            @click="typeChange(3)"
            >补欠款</el-button
          >
          <!-- <el-button type="primary" size="small" :plain="!(typeIndex==4)" @click="typeChange(4)">取单</el-button> -->
        </el-col>
      </el-col>
    </el-row>
    <!-- 佣金记录弹窗 -->
    <el-dialog title="佣金记录" :visible.sync="commissionDialog" width="1200px">
      <el-table size="small" :data="commissionData" height="360px">
        <el-table-column prop="BillDate" label="销售时间"></el-table-column>
        <el-table-column prop="EntityName" label="操作门店"></el-table-column>
        <el-table-column prop="BillID" label="销售订单"></el-table-column>
        <el-table-column prop="PayAmount" label="销售金额">
          <template slot-scope="scope">
            ￥{{ scope.row.PayAmount | NumFormat }}
          </template>
        </el-table-column>
        <el-table-column
          prop="ReturnedCommissionScale"
          label="返佣比例"
        ></el-table-column>
        <el-table-column prop="ReturnedCommissionAmount" label="返佣金额">
          <template slot-scope="scope">
            ￥{{ scope.row.ReturnedCommissionAmount | NumFormat }}
          </template>
        </el-table-column>
        <el-table-column
          prop="ReturnedCommissionType"
          label="返佣方式"
        ></el-table-column>
        <el-table-column label="操作人" width="100px">
          <template slot-scope="scope">
            {{ scope.row.EmployeeName }}
          </template>
        </el-table-column>
      </el-table>
      <div class="text_right pad_15">
        <el-pagination
          background
          v-if="commissionListPagination.Total > 0"
          @current-change="Getcommission"
          :current-page.sync="commissionListPagination.Page"
          :page-size="commissionListPagination.Page_size"
          :layout="commissionListPagination.layout"
          :total="commissionListPagination.Total"
        ></el-pagination>
      </div>
    </el-dialog>
    <!-- 新增弹窗 -->
    <el-dialog
      :title="isAdd ? '新增客户' : '编辑基本信息'"
      :visible.sync="isAddCustom"
      width="850px"
    >
      <el-tabs v-model="dialogVisibleActive">
        <el-tab-pane label="基本信息" name="1">
          <el-form
            :model="customer"
            :rules="customerRoules"
            ref="customer"
            label-width="90px"
            size="small"
          >
            <el-row>
              <el-col :span="12">
                <el-form-item label="会员姓名" prop="Name">
                  <el-input v-model="customer.Name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="手机号码" prop="PhoneNumber">
                  <el-input
                    v-model="customer.PhoneNumber"
                    maxlength="11"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="会员编号">
                  <el-input v-model="customer.Code"></el-input>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="12">
              <el-form-item label="会员来源">
                <el-cascader v-model="customer.CustomerSourceID" :options="customerSource" :props="{ checkStrictly: true ,children:'Child',value:'ID',label:'Name',emitPath:false}" :show-all-levels="false" clearable filterable></el-cascader>
              </el-form-item>
            </el-col> -->
              <el-col :span="6">
                <el-form-item label="会员来源" prop="CustomerSourceID">
                  <el-select
                    v-model="customer.CustomerSourceID"
                    placeholder="请选择"
                    filterable
                    size="small"
                  >
                    <el-option :key="0" label="老带新" :value="1"> </el-option>
                    <el-option
                      v-for="item in customerSource"
                      :key="item.ID"
                      :label="item.Name"
                      :value="item.ID"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6" v-show="customer.CustomerSourceID == '1'">
                <el-form-item class="CustomerSourceID">
                  <el-select
                    v-model="customer.ReferencesID"
                    placeholder="请选择推荐会员"
                    filterable
                    size="small"
                    v-loadmore="loadMore"
                    remote
                    :remote-method="remoteMethod"
                    :loading="remoteLoading"
                    @focus="CustomerList"
                  >
                    <el-option
                      v-for="item in customerbillList"
                      :key="item.ID"
                      :label="item.Name"
                      :value="item.ID"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="所属顾问">
                  <el-select
                    v-model="customer.EmployeeID"
                    placeholder="请选择所属顾问"
                    clearable
                    filterable
                    size="small"
                    multiple
                    collapse-tags
                  >
                    <el-option
                      v-for="item in employee"
                      :key="item.ID"
                      :label="item.Name"
                      :value="item.ID"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="会员等级">
                  <el-select
                    v-model="customer.CustomerLevelID"
                    placeholder="请选择会员等级"
                    clearable
                    filterable
                    size="small"
                  >
                    <el-option
                      v-for="item in customerLevel"
                      :key="item.ID"
                      :label="item.Name"
                      :value="item.ID"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="会员生日">
                  <el-date-picker
                    v-model="customer.Birthday"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="会员性别">
                  <el-radio-group v-model="customer.Gender">
                    <el-radio label="2">女</el-radio>
                    <el-radio label="1">男</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="档案信息" name="2">
          <div style="height: 50vh">
            <el-scrollbar class="el-scrollbar_height">
              <el-form ref="recordForm" label-width="110px" size="small">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="年龄">
                      <el-input
                        v-model="recordForm.Age"
                        class="width_220"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="职业">
                      <el-input
                        v-model="recordForm.Occupation"
                        class="width_220"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="大腿左/右">
                      <el-input
                        v-model="recordForm.Thigh"
                        class="width_220"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="小腿左/右">
                      <el-input
                        v-model="recordForm.Shank"
                        class="width_220"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="围度测量">
                      <el-input
                        v-model="recordForm.UmbilicalDimension"
                        class="width_220"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="胳膊左/右">
                      <el-input
                        v-model="recordForm.Arm"
                        class="width_220"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="是否拍照">
                      <el-radio v-model="recordForm.IsPhotograph" :label="true"
                        >是</el-radio
                      >
                      <el-radio v-model="recordForm.IsPhotograph" :label="false"
                        >否</el-radio
                      >
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="最想改善的问题">
                      <el-select
                        v-model="recordForm.ImproveProblem"
                        multiple
                        placeholder="请选择"
                        value-key="value"
                        class="width_220"
                      >
                        <el-option
                          v-for="item in problem"
                          :key="item.value"
                          :value="item.value"
                          :label="item.label"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="月经是否准时">
                      <el-select
                        v-model="recordForm.Menses"
                        placeholder="请选择"
                        value-key="value"
                        class="width_220"
                      >
                        <el-option
                          v-for="item in periodList"
                          :key="item.value"
                          :value="item.value"
                          :label="item.label"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="胸部">
                      <el-select
                        v-model="recordForm.Chest"
                        multiple
                        placeholder="请选择"
                        value-key="value"
                        class="width_220"
                      >
                        <el-option
                          v-for="item in chestList"
                          :key="item.value"
                          :value="item.value"
                          :label="item.label"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="肩颈">
                      <el-select
                        v-model="recordForm.ShoulderNeck"
                        multiple
                        placeholder="请选择"
                        value-key="value"
                        class="width_220"
                      >
                        <el-option
                          v-for="item in shouldersList"
                          :key="item.value"
                          :value="item.value"
                          :label="item.label"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="入睡时间">
                      <el-select
                        v-model="recordForm.SleepTime"
                        placeholder="请选择"
                        value-key="value"
                        class="width_220"
                      >
                        <el-option
                          v-for="item in sleepList"
                          :key="item.value"
                          :value="item.value"
                          :label="item.label"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="睡眠质量">
                      <el-select
                        v-model="recordForm.SleepQuality"
                        placeholder="请选择"
                        value-key="value"
                        class="width_220"
                      >
                        <el-option
                          v-for="item in qualityList"
                          :key="item.value"
                          :value="item.value"
                          :label="item.label"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="排便情况">
                      <el-select
                        v-model="recordForm.Defecation"
                        placeholder="请选择"
                        value-key="value"
                        class="width_220"
                      >
                        <el-option
                          v-for="item in defecateList"
                          :key="item.value"
                          :value="item.value"
                          :label="item.label"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="腰部情况">
                      <el-select
                        v-model="recordForm.WaistCondition"
                        placeholder="请选择"
                        value-key="value"
                        class="width_220"
                      >
                        <el-option
                          v-for="item in waistList"
                          :key="item.value"
                          :value="item.value"
                          :label="item.label"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="手脚是否冰凉">
                      <el-select
                        v-model="recordForm.IceCold"
                        placeholder="请选择"
                        value-key="value"
                        class="width_220"
                      >
                        <el-option
                          v-for="item in ColdHandList"
                          :key="item.value"
                          :value="item.value"
                          :label="item.label"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="饮食喜好">
                      <el-select
                        v-model="recordForm.FoodPreference"
                        multiple
                        placeholder="请选择"
                        value-key="value"
                        class="width_220"
                      >
                        <el-option
                          v-for="item in biteList"
                          :key="item.value"
                          :value="item.value"
                          :label="item.label"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="工作环境">
                      <el-select
                        v-model="recordForm.WorkEnvironment"
                        placeholder="请选择"
                        value-key="value"
                        class="width_220"
                      >
                        <el-option
                          v-for="item in environmentList"
                          :key="item.value"
                          :value="item.value"
                          :label="item.label"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="A型">
                      <el-select
                        v-model="recordForm.TypeA"
                        placeholder="请选择"
                        value-key="value"
                        class="width_220"
                      >
                        <el-option
                          v-for="item in typeAList"
                          :key="item.value"
                          :value="item.value"
                          :label="item.label"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="健康状况">
                      <el-select
                        v-model="recordForm.Health"
                        placeholder="请选择"
                        value-key="value"
                        class="width_220"
                      >
                        <el-option
                          v-for="item in healthList"
                          :key="item.value"
                          :value="item.value"
                          :label="item.label"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="H型">
                      <el-select
                        v-model="recordForm.TypeH"
                        placeholder="请选择"
                        value-key="value"
                        class="width_220"
                      >
                        <el-option
                          v-for="item in typeHList"
                          :key="item.value"
                          :value="item.value"
                          :label="item.label"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="V型">
                      <el-select
                        v-model="recordForm.TypeV"
                        placeholder="请选择"
                        value-key="value"
                        class="width_220"
                      >
                        <el-option
                          v-for="item in typeVList"
                          :key="item.value"
                          :value="item.value"
                          :label="item.label"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="食疗建议">
                      <el-select
                        v-model="recordForm.DietAdvice"
                        placeholder="请选择"
                        value-key="value"
                        class="width_220"
                      >
                        <el-option
                          v-for="item in dietaryTherapyList"
                          :key="item.value"
                          :value="item.value"
                          :label="item.label"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="调理建议">
                      <el-select
                        v-model="recordForm.SuggestionsConditioning"
                        multiple
                        placeholder="请选择"
                        value-key="value"
                        class="width_220"
                      >
                        <el-option
                          v-for="item in nurseList"
                          :key="item.value"
                          :value="item.value"
                          :label="item.label"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </el-scrollbar>
          </div>
        </el-tab-pane>
      </el-tabs>

      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="isAddCustom = false" v-prevent-click
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="submitCustomer"
          :loading="modalLoading"
          v-prevent-click
          >保存并开单</el-button
        >
      </div>
    </el-dialog>
    <!--详情弹窗-->
    <el-dialog
      title="客户详情"
      :visible.sync="dialogDetail"
      width="1200px"
      :before-close="customerDetailBeforeClose"
    >
      <div class="customer_information">
        <el-row type="flex" align="middle">
          <el-col :span="2">
            <el-upload
              :disabled="!customInfoEdit && !customerEntityBool"
              action=""
              :show-file-list="false"
              :before-upload="beforeAvatarUpload"
              :http-request="updateCustomerUploadImage"
            >
              <el-avatar
                :size="70"
                :src="customerDetail.Avatar ? customerDetail.Avatar : circleUrl"
              ></el-avatar>
            </el-upload>
          </el-col>
          <el-col :span="22">
            <el-row type="flex" justify="space-between">
              <el-col :span="12">
                <strong class="marrt_5 font_18">{{
                  customerDetail.Name
                }}</strong>
                <el-image
                  v-if="customerDetail.Gender == 2"
                  style="width: 8px; height: 12px"
                  :src="require('@/assets//img//gender-female.png')"
                ></el-image>
                <el-image
                  v-if="customerDetail.Gender == 1"
                  style="width: 8px; height: 12px"
                  :src="require('@/assets/img/gender-male.png')"
                ></el-image>
                <!-- <el-button
                  v-if="customerEntityBool || customInfoEdit"
                  class="padlt_10"
                  size="mini"
                  type="text"
                  @click="showEditDialog(customerDetail)"
                  >编辑
                </el-button> -->
              </el-col>
              <!-- <el-col :span="12" class="text_right">
                <el-button
                  size="small"
                  type="primary"
                  @click="navCreateBill"
                  plain
                  v-if="openBillState"
                  >开单</el-button
                >
                <el-button
                  size="small"
                  type="primary"
                  plain
                  @click="clickAppointment"
                  v-if="AppointmentState"
                  >预约</el-button
                >
              </el-col> -->
            </el-row>
            <el-row class="martp_10">
              <el-col :span="6" class="color_999"
                >手机号：<span class="color_333">{{
                  customerDetail.PhoneNumber
                }}</span></el-col
              >
              <el-col :span="6" v-if="customerDetail">
                <el-tooltip
                  :manual="
                    customerDetail.Employee &&
                    customerDetail.Employee.length > 0
                      ? false
                      : true
                  "
                  :content="formatterEmployeeNames(customerDetail)"
                  placement="bottom"
                  effect="light"
                >
                  <div
                    style="
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      overflow: hidden;
                    "
                    class="color_999"
                  >
                    营销顾问：<span class="color_333">{{
                      formatterEmployeeNames(customerDetail)
                    }}</span>
                  </div>
                </el-tooltip>
              </el-col>
              <el-col :span="6" class="color_999"
                >归属门店：<span class="color_333"> {{customerDetail.EntityName}} </span></el-col
              >
              <el-col :span="6" class="color_999"
                >会员编号：<span class="color_333">{{
                  customerDetail.Code
                }}</span></el-col
              >
            </el-row>
            <el-row class="martp_10">
              <el-col :span="6" class="color_999"
                >顾客来源：<span class="color_333">{{
                  customerDetail.CustomerSourceName
                }}</span></el-col
              >
              <el-col :span="6" class="color_999"
                >顾客等级：<span class="color_333">{{
                  customerDetail.CustomerLevelName
                }}</span></el-col
              >
              <el-col :span="6" class="color_999"
                >会员生日：<span class="color_333">{{
                  customerDetail.Birthday
                }}</span></el-col
              >
              <el-col :span="6" class="color_999"
                >注册时间：<span class="color_333">{{
                  customerDetail.CreatedOn
                }}</span></el-col
              >
            </el-row>
          </el-col>
        </el-row>
      </div>
      <div class="customer_content">
        <el-button
          type="primary"
          size="small"
          class="AddNurse"
          v-show="tabPane == 7"
          @click="AddNurse"
          >填写</el-button
        >
        <el-tabs v-model="tabPane" @tab-click="handleClick">
          <el-tab-pane label="客户档案" name="0">
            <div class="customer_record">
              <el-row class="martp_15">
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999">年龄:</el-col>
                  <el-col
                    :span="17"
                    :offset="1"
                    class="text_ellipsis color_000"
                    >{{ Record.Age || "-" }}</el-col
                  >
                </el-col>
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999">职业:</el-col>
                  <el-col
                    :span="17"
                    :offset="1"
                    class="text_ellipsis color_000"
                    >{{ Record.Occupation || "-" }}</el-col
                  >
                </el-col>
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999">脐纬:</el-col>
                  <el-col
                    :span="17"
                    :offset="1"
                    class="text_ellipsis color_000"
                    >{{ Record.UmbilicalDimension || "-" }}</el-col
                  >
                </el-col>
              </el-row>
              <el-row class="martp_20">
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999"
                    >大腿左/右:</el-col
                  >
                  <el-tooltip
                    class="item"
                    effect="light"
                    :open-delay="500"
                    placement="top-start"
                    v-if="Record.Thigh && Record.Thigh.length > 15"
                  >
                    <div slot="content" class="width_220">
                      {{ Record.Thigh }}
                    </div>
                    <el-col
                      :span="17"
                      :offset="1"
                      class="text_ellipsis color_000"
                      >{{ Record.Thigh }}</el-col
                    >
                  </el-tooltip>
                  <el-col
                    :span="17"
                    :offset="1"
                    v-else
                    class="text_ellipsis color_000"
                    >{{ Record.Thigh || "-" }}</el-col
                  >
                </el-col>
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999"
                    >小腿左/右:</el-col
                  >
                  <el-tooltip
                    class="item"
                    effect="light"
                    :open-delay="500"
                    placement="top-start"
                    v-if="Record.Shank && Record.Shank.length > 15"
                  >
                    <div slot="content" class="width_220">
                      {{ Record.Shank }}
                    </div>
                    <el-col
                      :span="17"
                      :offset="1"
                      class="text_ellipsis color_000"
                      >{{ Record.Shank }}</el-col
                    >
                  </el-tooltip>
                  <el-col
                    :span="17"
                    :offset="1"
                    v-else
                    class="text_ellipsis color_000"
                    >{{ Record.Shank || "-" }}</el-col
                  >
                </el-col>
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999"
                    >胳膊左右:</el-col
                  >
                  <el-tooltip
                    class="item"
                    effect="light"
                    :open-delay="500"
                    placement="top-start"
                    v-if="Record.Arm && Record.Arm.length > 15"
                  >
                    <div slot="content" class="width_220">{{ Record.Arm }}</div>
                    <el-col
                      :span="17"
                      :offset="1"
                      class="text_ellipsis color_000"
                      >{{ Record.Arm }}</el-col
                    >
                  </el-tooltip>
                  <el-col
                    :span="17"
                    :offset="1"
                    v-else
                    class="text_ellipsis color_000"
                    >{{ Record.Arm || "-" }}</el-col
                  >
                </el-col>
              </el-row>
              <el-row class="martp_20">
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999"
                    >是否拍照:</el-col
                  >
                  <el-col
                    :span="17"
                    :offset="1"
                    class="text_ellipsis color_000"
                    >{{ Record.IsPhotograph ? "是" : "否" }}</el-col
                  >
                </el-col>
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999"
                    >最想改善问题:</el-col
                  >
                  <el-tooltip
                    class="item"
                    effect="light"
                    :open-delay="500"
                    placement="top-start"
                    v-if="
                      Record.ImproveProblem && Record.ImproveProblem.length > 5
                    "
                  >
                    <div slot="content" class="width_220">
                      {{ Record.ImproveProblem.join(",") }}
                    </div>
                    <el-col
                      :span="17"
                      :offset="1"
                      class="text_ellipsis color_000"
                      >{{ Record.ImproveProblem.join(",") }}</el-col
                    >
                  </el-tooltip>
                  <el-col
                    :span="17"
                    :offset="1"
                    v-else
                    class="text_ellipsis color_000"
                    >{{ Record.ImproveProblem.join(",") || "-" }}</el-col
                  >
                </el-col>
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999"
                    >月经是否准时:</el-col
                  >
                  <el-col
                    :span="17"
                    :offset="1"
                    class="text_ellipsis color_000"
                    >{{ Record.Menses || "-" }}</el-col
                  >
                </el-col>
              </el-row>
              <el-row class="martp_20">
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999">胸部:</el-col>
                  <el-tooltip
                    class="item"
                    effect="light"
                    :open-delay="500"
                    placement="top-start"
                    v-if="Record.Chest && Record.Chest.length > 5"
                  >
                    <div slot="content" class="width_220">
                      {{ Record.Chest.join(",") }}
                    </div>
                    <el-col
                      :span="17"
                      :offset="1"
                      class="text_ellipsis color_000"
                      >{{ Record.Chest.join(",") }}</el-col
                    >
                  </el-tooltip>
                  <el-col
                    :span="17"
                    :offset="1"
                    v-else
                    class="text_ellipsis color_000"
                    >{{ Record.Chest.join(",") || "-" }}</el-col
                  >
                </el-col>
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999">肩颈:</el-col>
                  <el-tooltip
                    class="item"
                    effect="light"
                    :open-delay="500"
                    placement="top-start"
                    v-if="Record.ShoulderNeck && Record.ShoulderNeck.length > 5"
                  >
                    <div slot="content" class="width_220">
                      {{ Record.ShoulderNeck.join(",") }}
                    </div>
                    <el-col
                      :span="17"
                      :offset="1"
                      class="text_ellipsis color_000"
                      >{{ Record.ShoulderNeck.join(",") }}</el-col
                    >
                  </el-tooltip>
                  <el-col
                    :span="17"
                    :offset="1"
                    v-else
                    class="text_ellipsis color_000"
                    >{{ Record.ShoulderNeck.join(",") || "-" }}</el-col
                  >
                </el-col>
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999"
                    >入睡时间:</el-col
                  >
                  <el-col
                    :span="17"
                    :offset="1"
                    class="text_ellipsis color_000"
                    >{{ Record.SleepTime || "-" }}</el-col
                  >
                </el-col>
              </el-row>
              <el-row class="martp_20">
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999"
                    >睡眠质量:</el-col
                  >
                  <el-col
                    :span="17"
                    :offset="1"
                    class="text_ellipsis color_000"
                    >{{ Record.SleepQuality || "-" }}</el-col
                  >
                </el-col>
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999"
                    >排便情况:</el-col
                  >
                  <el-col
                    :span="17"
                    :offset="1"
                    class="text_ellipsis color_000"
                    >{{ Record.Defecation || "-" }}</el-col
                  >
                </el-col>
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999"
                    >腰部情况:</el-col
                  >
                  <el-col
                    :span="17"
                    :offset="1"
                    class="text_ellipsis color_000"
                    >{{ Record.WaistCondition || "-" }}</el-col
                  >
                </el-col>
              </el-row>
              <el-row class="martp_20">
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999"
                    >手脚是否冰凉:</el-col
                  >
                  <el-col
                    :span="17"
                    :offset="1"
                    class="text_ellipsis color_000"
                    >{{ Record.IceCold || "-" }}</el-col
                  >
                </el-col>
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999"
                    >饮食喜好:</el-col
                  >
                  <el-tooltip
                    class="item"
                    effect="light"
                    :open-delay="500"
                    placement="top-start"
                    v-if="
                      Record.FoodPreference && Record.FoodPreference.length > 5
                    "
                  >
                    <div slot="content" class="width_220">
                      {{ Record.FoodPreference.join(",") }}
                    </div>
                    <el-col
                      :span="17"
                      :offset="1"
                      class="text_ellipsis color_000"
                      >{{ Record.FoodPreference.join(",") }}</el-col
                    >
                  </el-tooltip>
                  <el-col
                    :span="17"
                    :offset="1"
                    v-else
                    class="text_ellipsis color_000"
                    >{{ Record.FoodPreference.join(",") || "-" }}</el-col
                  >
                </el-col>
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999"
                    >工作环境:</el-col
                  >
                  <el-col
                    :span="17"
                    :offset="1"
                    class="text_ellipsis color_000"
                    >{{ Record.WorkEnvironment || "-" }}</el-col
                  >
                </el-col>
              </el-row>
              <el-row class="martp_20">
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999">A型:</el-col>
                  <el-col
                    :span="17"
                    :offset="1"
                    class="text_ellipsis color_000"
                    >{{ Record.TypeA || "-" }}</el-col
                  >
                </el-col>
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999">H型:</el-col>
                  <el-col
                    :span="17"
                    :offset="1"
                    class="text_ellipsis color_000"
                    >{{ Record.TypeH || "-" }}</el-col
                  >
                </el-col>
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999">V型:</el-col>
                  <el-col
                    :span="17"
                    :offset="1"
                    class="text_ellipsis color_000"
                    >{{ Record.TypeV || "-" }}</el-col
                  >
                </el-col>
              </el-row>
              <el-row class="martp_20">
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999"
                    >健康状况:</el-col
                  >
                  <el-col
                    :span="17"
                    :offset="1"
                    class="text_ellipsis color_000"
                    >{{ Record.Health || "-" }}</el-col
                  >
                </el-col>
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999"
                    >食疗建议:</el-col
                  >
                  <el-tooltip
                    class="item"
                    effect="light"
                    :open-delay="500"
                    placement="top-start"
                    v-if="Record.DietAdvice && Record.DietAdvice.length > 15"
                  >
                    <div slot="content" class="width_220">
                      {{ Record.DietAdvice }}
                    </div>
                    <el-col
                      :span="17"
                      :offset="1"
                      class="text_ellipsis color_000"
                      >{{ Record.DietAdvice }}</el-col
                    >
                  </el-tooltip>
                  <el-col
                    :span="17"
                    :offset="1"
                    v-else
                    class="text_ellipsis color_000"
                    >{{ Record.DietAdvice || "-" }}</el-col
                  >
                </el-col>
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999"
                    >调理建议:</el-col
                  >
                  <el-tooltip
                    class="item"
                    effect="light"
                    :open-delay="500"
                    placement="top-start"
                    v-if="
                      Record.SuggestionsConditioning &&
                      Record.SuggestionsConditioning.length > 15
                    "
                  >
                    <div slot="content" class="width_220">
                      {{ Record.SuggestionsConditioning.join(",") }}
                    </div>
                    <el-col
                      :span="17"
                      :offset="1"
                      class="text_ellipsis color_000"
                      >{{ Record.SuggestionsConditioning.join(",") }}</el-col
                    >
                  </el-tooltip>
                  <el-col
                    :span="17"
                    :offset="1"
                    v-else
                    class="text_ellipsis color_000"
                    >{{
                      Record.SuggestionsConditioning.join(",") || "-"
                    }}</el-col
                  >
                </el-col>
              </el-row>
            </div>
          </el-tab-pane>
          <el-tab-pane label="客户标签" name="1">
            <div class="tip">
              <el-row type="flex" justify="space-between" align="middle">
                <el-col :span="12">标签</el-col>
                <el-col :span="12" class="text_right">
                  <el-button
                    size="mini"
                    type="text"
                    style="padding: 0px"
                    @click="tagClick"
                    >编辑</el-button
                  >
                </el-col>
              </el-row>
            </div>
            <div class="martp_5">
              <el-tag
                v-for="(item, index) in customerTag"
                :key="index"
                effect="plain"
                class="mar_5"
                >{{ item.Name }}
              </el-tag>
            </div>
          </el-tab-pane>

          <el-tab-pane label="卡项信息" name="2">
            <customer-account
              :customerID="customerID"
              ref="customerAccount"
            ></customer-account>
          </el-tab-pane>

          <el-tab-pane label="订单信息" name="3">
            <customerbill
              :customerID="customerID"
              ref="customerbill"
            ></customerbill>
          </el-tab-pane>

          <el-tab-pane label="预约记录" name="4">
            <appointmentRecord
              :customerID="customerID"
              ref="appointmentRecord"
            ></appointmentRecord>
          </el-tab-pane>
          <el-tab-pane label="推荐顾客列表" name="5">
            <el-table size="small" :data="recommendList" height="360px">
              <el-table-column prop="Name" label="顾客名称"></el-table-column>
              <el-table-column
                prop="PhoneNumber"
                label="手机号"
              ></el-table-column>
              <el-table-column
                prop="LevelName"
                label="会员等级"
              ></el-table-column>
              <el-table-column prop="PayAmount" label="消费金额">
                <template slot-scope="scope">
                  ￥{{ scope.row.PayAmount | NumFormat }}
                </template>
              </el-table-column>
              <el-table-column prop="ReturnedCommissionAmount" label="返佣金额">
                <template slot-scope="scope">
                  ￥{{ scope.row.ReturnedCommissionAmount | NumFormat }}
                </template>
              </el-table-column>
              <el-table-column
                prop="CreatedOn"
                label="注册时间"
              ></el-table-column>
              <el-table-column label="操作" width="100px">
                <template slot-scope="scope">
                  <el-button
                    type="primary"
                    size="small"
                    @click="Setcommission(scope.row)"
                    v-prevent-click
                    >返佣记录
                  </el-button>
                  <!-- <el-button v-if="scope.row.ArrearAmount > 0" type="primary" size="small" @click="balancingAccount(scope.row, 0)" v-prevent-click>平账
              </el-button> -->
                </template>
              </el-table-column>
            </el-table>
            <div class="text_right pad_15">
              <el-pagination
                background
                v-if="recommendListPagination.Total > 0"
                @current-change="GetRecommendList"
                :current-page.sync="recommendListPagination.Page"
                :page-size="recommendListPagination.Page_size"
                :layout="recommendListPagination.layout"
                :total="recommendListPagination.Total"
              ></el-pagination>
            </div>
          </el-tab-pane>
          <el-tab-pane label="护理计划" name="6">
            <NursingLog :customerID="customerID" ref="nursingLog"></NursingLog>
          </el-tab-pane>
          <el-tab-pane label="调理减重计划" name="7">
            <NurseHealth
              ref="NurseHealth"
              :customerID="customerID"
            ></NurseHealth>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-dialog>

    <!--标签弹窗-->
    <el-dialog title="编辑标签" :visible.sync="dialogTag" width="1000px">
      <el-row style="max-height: 130px; overflow-y: auto">
        <el-tag
          v-for="(item, index) in editCustomerTag"
          :key="index"
          closable
          @close="removeTag(index)"
          effect="plain"
          class="mar_5"
          >{{ item.Name }}
        </el-tag>
      </el-row>
      <el-row class="pad_5" v-if="customTagLibrary">
        <el-col :span="10">
          <div
            class="el-form-item el-form-item--small"
            style="margin-bottom: 0px"
          >
            <label class="el-form-item__label" style="width: 98px"
              >自定义标签：</label
            >
            <div class="el-form-item__content" style="margin-left: 98px">
              <div class="el-input el-input--small">
                <el-input
                  type="text"
                  autocomplete="off"
                  placeholder="标签名限8个字"
                  v-model="tagName"
                  maxlength="8"
                  size="small"
                  clearable
                >
                  <template slot="append">
                    <el-button
                      size="small"
                      @click="addTagClick"
                      clearable
                      v-prevent-click
                      >添加</el-button
                    >
                  </template>
                </el-input>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row class="pad_5">
        <div class="pad_5_0">选择已有标签</div>
        <el-col
          style="height: 180px; overflow-y: auto"
          class="border radius5 pad_10"
        >
          <el-tag
            v-for="item in tagList"
            :key="item.ID"
            :type="item.type"
            effect="plain"
            @click="tagSelectClick(item)"
            class="cursor_pointer mar_5"
            >{{ item.Name }}
          </el-tag>
        </el-col>
      </el-row>
      <div slot="footer">
        <el-button size="small" @click="dialogTag = false" v-prevent-click
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="tagSaveClick"
          :loading="modalLoading"
          v-prevent-click
          >保存
        </el-button>
      </div>
    </el-dialog>
    <!-- 快速开单 -->
    <AgileSell
      v-if="isAgileSell"
      v-show="typeIndex == 0"
      :isSupplement="IsSupplement"
      :billDate="BillDate"
      :customerID="customerID"
      :customerFullName="customerFullName"
      :customerPhoneNumber="customerPhoneNumber"
      :SellPermission="SellPermission"
      ref="agileSellComponents"
    ></AgileSell>
    <!-- 销售 -->
    <sell
      v-show="typeIndex == 1"
      :isSupplement="IsSupplement"
      :billDate="BillDate"
      :CustomerID="customerID"
      :IsReturnedCommission="customerDetail.IsReturnedCommission"
      :customerFullName="customerFullName"
      :customerPhoneNumber="customerPhoneNumber"
      :SellPermission="SellPermission"
      ref="sellComponents"
    ></sell>
    <!-- 消耗 -->
    <consume
      v-show="typeIndex == 2"
      :isSupplement="IsSupplement"
      :billDate="BillDate"
      :CustomerID="customerID"
      :customerFullName="customerFullName"
      :customerPhoneNumber="customerPhoneNumber"
      :TreatPermission="TreatPermission"
      ref="consumeComponents"
    ></consume>
    <!--补欠款-->
    <workOffArrears
      v-show="typeIndex == 3"
      :isSupplement="IsSupplement"
      :billDate="BillDate"
      :CustomerID="customerID"
      :customerFullName="customerFullName"
      :customerPhoneNumber="customerPhoneNumber"
      :IsReturnedCommission="customerDetail.IsReturnedCommission"
      ref="workOffArrearsComponents"
    ></workOffArrears>
  </div>
</template>

<script>
import API from "@/api/iBeauty/Order/saleGoods";
import APITagLibrary from "@/api/CRM/Customer/customerTagLibrary";
import AgileSell from "@/components/iBeauty/Order/agileSell";
import Sell from "@/components/iBeauty/Order/sell";
import Consume from "@/components/iBeauty/Order/consume";
import WorkOffArrears from "@/components/iBeauty/Order/workOffArrears";
import APICustomer from "@/api/CRM/Customer/customer";
import APICustomerLevel from "@/api/CRM/Customer/customerLevel";
import APICustomerSource from "@/api/CRM/Customer/customerSource";
import validate from "@/components/js/validate.js";
import permission from "@/components/js/permission.js";
//顾客详情组件
import customerbill from "@/components/iBeauty/Customer/customerBill";
import CustomerAccount from "../../../components/iBeauty/Customer/customerAccount";
import appointmentRecord from "@/components/iBeauty/Customer/appointmentRecord";
import NursingLog from "@/components/iBeauty/Customer/CustomerNursingLog";
import NurseHealth from "@/components/iBeauty/Customer/NurseHealth";
var Enumerable = require("linq");
export default {
  name: "Bill",
  components: {
    AgileSell,
    Sell,
    Consume,
    WorkOffArrears,
    customerbill,
    CustomerAccount,
    appointmentRecord,
    NurseHealth,
    NursingLog,
  },
  data() {
    return {
      add: "2",
      isReplacementOrder: false,
      isAgileSell: true,
      SellPermission: {
        ModifyPrices_SaleGeneralCard: false,
        ModifyPrices_SalePackageCard: false,
        ModifyPrices_SaleProduct: false,
        ModifyPrices_SaleProject: false,
        // ModifyPrices_SaleSavingCard:false,
        ModifyPrices_SaleTimeCard: false,
      },
      TreatPermission: {
        ModifyPrices_TreatSavingCard: false,
      },
      loading: false,
      isAddCustom: false,
      IsSupplement: false,
      BillDate: null,
      pickerOptions: {
        disabledDate(time) {
          return (
            time.getTime() > Date.now()
            // ||
            // time.getTime() < Date.now() - 3600 * 1000 * 24 * 7
          );
        },
      },
      customerName: "",
      customerID: null,
      customerFullName: "",
      customerPhoneNumber: "",
      typeIndex: 0,
      modalLoading: false,
      customer: {
        Name: "",
        PhoneNumber: "",
        Gender: "2",
        CustomerSourceID: null,
        EmployeeID: [],
        CustomerLevelID: "",
        Code: "",
        Birthday: "",
        ReferencesID: "",
      },
      customerRoules: {
        Name: [{ required: true, message: "请输入客户名称", trigger: "blur" }],
        PhoneNumber: [
          {
            required: true,
            validator: validate.validPhoneNumber,
            trigger: "blur",
          },
        ],
        Gender: [
          { required: true, message: "请选择客户性别", trigger: "change" },
        ],
        CustomerSourceID: [
          { required: true, message: "请选择客户来源", trigger: "change" },
        ],
        EmployeeID: [
          { required: true, message: "请选择顾问", trigger: "change" },
        ],
        CustomerLevelID: [
          { required: true, message: "请选择客户等级", trigger: "change" },
        ],
        Code: [{ required: true, message: "请输入客户编号", trigger: "blur" }],
      },
      employee: [], //营销顾问
      customerLevel: [], //顾客等级
      customerSource: [], //顾客来源
      addCustomerPhoneNumber: "", // 新增时 顾客的手机号
      isAdd: true,
      dialogVisibleActive: "1",
      qualityList: [
        { value: "很好", label: "很好" },
        { value: "一般", label: "一般" },
        { value: "差", label: "差" },
      ],
      problem: [
        //最想改善的问题
        { value: "瘦肚子", label: "瘦肚子" },
        { label: "瘦腿", value: "瘦腿" },
        { value: "瘦背", label: "瘦背" },
        { value: "瘦胳膊", label: "瘦胳膊" },
        { value: "全身减重", label: "全身减重" },
        { value: "疏通经络", label: "疏通经络" },
        { value: "胸部胀痛", label: "胸部胀痛" },
        { value: "胸部松弛", label: "胸部松弛" },
        { value: "臀部下垂", label: "臀部下垂" },
        { value: "全身松弛", label: "全身松弛" },
        { value: "代谢缓慢", label: "代谢缓慢" },
        { value: "身体寒凉", label: "身体寒凉" },
      ],
      periodList: [
        { value: "提前", label: "提前" },
        { label: "延迟", value: "延迟" },
        { value: "准时", label: "准时" },
      ],
      chestList: [
        { value: "胀痛", label: "胀痛" },
        { value: "增生", label: "增生" },
        { value: "结节", label: "结节" },
      ],
      shouldersList: [
        { value: "僵硬", label: "僵硬" },
        { value: "酸痛", label: "酸痛" },
      ],
      sleepList: [
        { value: "11点前", label: "11点前" },
        { value: "12点前", label: "12点前" },
        { value: "12点后", label: "12点后" },
      ],
      defecateList: [
        { value: "经常便秘", label: "经常便秘" },
        { value: "偶尔便秘", label: "偶尔便秘" },
        { value: "正常", label: "正常" },
        { value: "腹泻", label: "腹泻" },
      ],
      waistList: [
        { value: "经常酸痛/寒凉", label: "经常酸痛/寒凉" },
        { value: "偶尔酸痛/寒凉", label: "偶尔酸痛/寒凉" },
        { value: "正常", label: "正常" },
      ],
      ColdHandList: [
        { value: "经常手脚冰凉", label: "经常手脚冰凉" },
        { value: "正常", label: "正常" },
      ],
      biteList: [
        { value: "甜食", label: "甜食" },
        { value: "冰冷", label: "冰冷" },
        { value: "辛辣", label: "辛辣" },
        { value: "海鲜", label: "海鲜" },
        { value: "油炸", label: "油炸" },
        { value: "果蔬类居多", label: "果蔬类居多" },
        { value: "肉食类居多", label: "肉食类居多" },
        { value: "规律", label: "规律" },
        { value: "不规律", label: "不规律" },
      ],
      environmentList: [
        { value: "室外居多", label: "室外居多" },
        { value: "空调房居多", label: "空调房居多" },
      ],
      typeAList: [
        { value: "带脉凸(不通)", label: "带脉凸(不通)" },
        { value: "大腿外翻(肝胆经不通)", label: "大腿外翻(肝胆经不通)" },
      ],
      healthList: [
        { value: "有过重大手术", label: "有过重大手术" },
        { value: "有过轻微手术", label: "有过轻微手术" },
        { value: "正常", label: "正常" },
      ],
      typeHList: [
        { value: "胳膊、腿偏粗", label: "胳膊、腿偏粗" },
        { value: "易乏力/怕累", label: "易乏力/怕累" },
      ],
      typeVList: [
        { value: "上宽下小,背厚", label: "上宽下小,背厚" },
        {
          value: "肚子凸口(易带脉、任脉堵)",
          label: "肚子凸口(易带脉、任脉堵)",
        },
      ],
      dietaryTherapyList: [
        { value: "小米粥", label: "小米粥" },
        { value: "鸡蛋", label: "鸡蛋" },
        { value: "山楂+荷叶煮水", label: "山楂+荷叶煮水" },
        { value: "山楂+陈皮煮水", label: "山楂+陈皮煮水" },
        { value: "早饭按时吃", label: "早饭按时吃" },
        { value: "黄芪泡水", label: "黄芪泡水" },
      ],
      nurseList: [
        { value: "寒湿体质", label: "寒湿体质" },
        { value: "腿部疏通肝胆经", label: "腿部疏通肝胆经" },
        { value: "肚子疏通带脉", label: "肚子疏通带脉" },
        { value: "臀疗", label: "臀疗" },
        { value: "偏热性体质", label: "偏热性体质" },
        { value: "大背", label: "大背" },
        { value: "肚子", label: "肚子" },
        { value: "小周天", label: "小周天" },
        { value: "胳膊", label: "胳膊" },
        {
          value: "偏寒性体质(易造成免疫力低,四肢循环差,手脚易凉)",
          label: "偏寒性体质(易造成免疫力低,四肢循环差,手脚易凉)",
        },
        {
          value: "先让身体热起来(小周天+全身去寒)",
          label: "先让身体热起来(小周天+全身去寒)",
        },
      ],
      tagName: "",
      tagList: [],
      customTagLibrary: false, //自定义标签权限
      dialogTag: false,
      remoteLoading: false,
      CustomerNameOrPhone: "", //推荐会员搜索关键字
      customerbillList: [], //推荐会员
      customerTag: [], //顾客标签
      editCustomerTag: [],
      CustomerNamePaginations: {
        //推荐会员页码信息
        Total: 0,
        page: 1,
      },
      // 添加客户档案
      recordForm: {
        Age: "", //年龄
        Occupation: "", //工作
        Thigh: "", //大腿
        Shank: "", //小腿
        UmbilicalDimension: "", //围度测量
        Arm: "", //胳膊
        IsPhotograph: false, //拍照
        ImproveProblem: [], //最想改善的问题选择结果
        Menses: "", //月经
        Chest: [], //胸部
        ShoulderNeck: [], //肩部
        SleepTime: "", //入睡时间
        SleepQuality: "", //睡眠质量
        Defecation: "", // 排便情况
        WaistCondition: "", // 腰部情况
        IceCold: "", //手脚寒凉
        FoodPreference: [], //饮食
        WorkEnvironment: "", //工作环境
        TypeA: "", //A型
        Health: "", //健康状况
        TypeH: "", //H型
        TypeV: "", //V型
        DietAdvice: "", //食疗建议
        SuggestionsConditioning: [], //调理建议
      },
      // 客户档案
      Record: {
        Age: "",
        Occupation: "",
        Thigh: "",
        Shank: "",
        UmbilicalDimension: "",
        Arm: "",
        IsPhotograph: false,
        ImproveProblem: [],
        Menses: "",
        Chest: [],
        ShoulderNeck: [],
        SleepTime: "",
        SleepQuality: "",
        Defecation: "",
        WaistCondition: "",
        IceCold: "",
        FoodPreference: [],
        WorkEnvironment: "",
        TypeA: "",
        Health: "",
        TypeH: "",
        TypeV: "",
        DietAdvice: "",
        SuggestionsConditioning: [],
      },
      recommendList: [], //推荐顾客列表
      recommendListPagination: {
        Page: 1, // 当前位于哪页
        Total: 0, // 总数
        Page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
      commissionData: [],
      commissionDialog: false,
      commissionID: "", //佣金记录ID
      commissionListPagination: {
        Page: 1, // 当前位于哪页
        Total: 2, // 总数
        Page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
      tabPane: 0,
      customerDetail: "",
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      customerEntityBool: true, //是否属于当前门店
      dialogDetail: false, //详情弹窗
      customInfoEdit: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.customTagLibrary = permission.permission(
        to.meta.Permission,
        "iBeauty-Order-Bill-CustomTagLibrary"
      );
      vm.SellPermission.ModifyPrices_SaleGeneralCard = permission.permission(
        to.meta.Permission,
        "iBeauty-Order-Bill-SaleGeneralCard-ModifyPrices"
      );
      vm.SellPermission.ModifyPrices_SalePackageCard = permission.permission(
        to.meta.Permission,
        "iBeauty-Order-Bill-SalePackageCard-ModifyPrices"
      );
      vm.SellPermission.ModifyPrices_SaleProduct = permission.permission(
        to.meta.Permission,
        "iBeauty-Order-Bill-SaleProduct-ModifyPrices"
      );
      vm.SellPermission.ModifyPrices_SaleProject = permission.permission(
        to.meta.Permission,
        "iBeauty-Order-Bill-SaleProject-ModifyPrices"
      );

      vm.SellPermission.ModifyPrices_SaleTimeCard = permission.permission(
        to.meta.Permission,
        "iBeauty-Order-Bill-SaleTimeCard-ModifyPrices"
      );
      vm.TreatPermission.ModifyPrices_TreatSavingCard = permission.permission(
        to.meta.Permission,
        "iBeauty-Order-Bill-TreatSavingCardt-ModifyPrices"
      );
      vm.customInfoEdit = permission.permission(
        to.meta.Permission,
        "iBeauty-Order-Bill-ModifyNotBelongEntityCustomer"
      );
      // 是否显示开单
      vm.isAgileSell = permission.permission(
        to.meta.Permission,
        "iBeauty-Order-Bill-AgileSell"
      );
      // 是否显示补单
      vm.isReplacementOrder = permission.permission(
        to.meta.Permission,
        "iBeauty-Order-Bill-ReplacementOrder"
      );
      vm.typeIndex = vm.isAgileSell ? 1 : 0;
      if (to.params.customerID != undefined) {
        APICustomer.getCustomerInfo({ ID: to.params.customerID }).then(
          function (res) {
            if (res.StateCode == 200) {
              vm.customerID = res.Data.ID;
              vm.customerFullName = res.Data.Name;
              vm.customerPhoneNumber = res.Data.PhoneNumber;
              vm.customerName = res.Data.Name + "【" + res.Data.Code + "】";
              vm.customerChange();
              vm.$refs.consumeComponents.clearConsumeNetWorkData();
              vm.$refs.workOffArrearsComponents.clearConsumeNetWorkData();
            } else {
              this.$message.error({
                message: res.Message,
                duration: 2000,
              });
            }
          }
        );
      }
      if(to.params.customerRow){
          vm.customerDetail = to.params.customerRow
      }
    });
  },

  methods: {
    /**  列表顾问格式化  */
    formatterEmployeeNames(row) {
      return Enumerable.from(row.Employee)
        .select((i) => i.EmployeeName)
        .toArray()
        .join(", ");
    },
    // 标签列表
    tagData: function () {
      var that = this;
      APITagLibrary.customerTagLibraryAll()
        .then((res) => {
          if (res.StateCode == 200) {
            that.tagList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 标签
    tagClick: function () {
      var that = this;
      that.editCustomerTag = Object.assign([], that.customerTag);
      that.tagType();
      that.dialogTag = true;
    },
    tagType: function () {
      var that = this;
      that.tagList.forEach(function (item) {
        item.type = "info";
        that.editCustomerTag.forEach(function (tag) {
          if (item.ID == tag.ID) {
            item.type = "primary";
          }
        });
      });
    },
    // 添加标签
    addTagClick: function () {
      var that = this;
      var params = {
        Name: that.tagName,
      };
      APITagLibrary.customerTagLibraryCreate(params)
        .then(function (res) {
          if (res.StateCode === 200) {
            that.editCustomerTag.push(res.Data);
            that.tagList.push(res.Data);
            that.tagType();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },
    // 删除标签
    removeTag: function (index) {
      var that = this;
      that.editCustomerTag.splice(index, 1);
      that.tagType();
    },
    //
    beforeAvatarUpload(file) {
      let that = this;
      let isFileType = false;
      if (
        file.type === "image/jpg" ||
        file.type === "image/png" ||
        file.type === "image/jpeg"
      ) {
        isFileType = true;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isFileType) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
        return false;
      }

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (evt) {
        let base64 = evt.target.result;
        that.updateCustomerUploadImage(base64);
      };
      return false;
    },
    /**  上传头像请求  */
    updateCustomerUploadImage(base64) {
      let that = this;
      let params = {
        CustomerImage: base64,
        CustomerID: that.customerDetail.ID,
      };
      API.updateCustomerUploadImage(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.customerDetail.Avatar = res.Message;
          }
        })
        .finally(() => {});
    },
    /** 顾客详情 弹窗界面关闭前调用   */
    customerDetailBeforeClose(done) {
      let that = this;
      that.$refs.customerAccount.clearAccountData();
      that.$refs.customerbill.clearNetWorkData();
      that.$refs.appointmentRecord.clearAppoinmentRecordData();
      // that.$refs.nursingLog.clearListData();
      done();
    },
    // 编辑顾客
    showEditDialog: function (row) {
      var that = this;
      that.isAdd = false;
      that.CustomerNamePaginations.page = 1;
      that.customerbillList = [];
      row.EmployeeID = new Array();
      if (row.Employee && row.Employee.length > 0) {
        row.Employee.forEach((i) => {
          row.EmployeeID.push(i.EmployeeID);
        });
      }

      that.dialogVisibleActive = "1";
      that.ruleForm = Object.assign({}, row);
      that.recordForm = Object.assign({}, that.Record);
      that.dialogVisible = true;
      that.employeeData();
    },
    //加载更多
    loadMore() {
      var that = this;
      if (that.customerbillList.length >= CustomerNamePaginations.Total) {
        return;
      }
      // that.selectLoading = true;
      that.CustomerNamePaginations.page++;
      that.CustomerList();
    },
    // 远程搜索
    remoteMethod(query) {
      var that = this;
      that.customerbillList = [];
      that.CustomerNamePaginations.page = 1;
      that.CustomerNameOrPhone = query.trim();
      setTimeout(() => {
        that.CustomerList();
      }, 200);
    },
    //获取来源顾客列表
    CustomerList() {
      this.remoteLoading = true;
      var params = {
        CustomerNameOrPhone: this.CustomerNameOrPhone,
        PageNum: this.CustomerNamePaginations.page,
        CustomerID: this.customerID,
      };
      API.recommendCustomerList(params).then((res) => {
        this.remoteLoading = false;
        if (res.StateCode == 200) {
          this.customerbillList = res.List;
          this.CustomerNamePaginations.Total = res.Total;
        }
      });
    },
    // 标签保存
    tagSaveClick: function () {
      var that = this;
      that.modalLoading = true;
      var TagLibrary = Enumerable.from(that.editCustomerTag)
        .select((val) => val.ID)
        .toArray();
      var params = {
        ID: that.customerDetail.ID,
        TagLibrary: TagLibrary,
      };
      API.updateCustomerTagLibrary(params)
        .then(function (res) {
          if (res.StateCode === 200) {
            that.dialogTag = false;
            that.customerTagData();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },
    // 顾客标签
    customerTagData: function () {
      var that = this;
      var params = {
        ID: that.customerDetail.ID,
      };
      API.getCustomerTag(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.customerTag = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    //客户详情tab切换
    handleClick() {
      var that = this;
      var tabPane = this.tabPane;
      switch (tabPane) {
        case "0":
          that.GetRecord();
          break;
        case "1":
          that.customerTagData();
          break;
        case "2":
          that.$refs.customerAccount.customerID = that.customerID;
          that.$refs.customerAccount.activeName = "0";
          that.$refs.customerAccount.handleClick();
          break;
        case "3":
          that.$refs.customerbill.customerID = that.customerID;
          that.$refs.customerbill.searchSaleBill();
          that.$refs.customerbill.clearSearchData();
          break;
        case "4":
          that.$refs.appointmentRecord.customerID = that.customerID;
          that.$refs.appointmentRecord.getAppointmentRecordList();
          break;
        case "5":
          that.GetRecommendList();
          break;
        case "6":
          that.$refs.nursingLog.customerID = that.customerID;
          that.$refs.nursingLog.clearListData();
          that.$refs.nursingLog.getNursingLogList();
          break;
        case "7":
          this.$refs.NurseHealth.DataList = [];
          this.$refs.NurseHealth.getNurseHealth();
          break;
      }
    },
    // 选择标签
    tagSelectClick: function (row) {
      var that = this;
      if (row.type == "info") {
        that.editCustomerTag.push(row);
      }
      that.tagType();
    },
    //获取推荐顾客列表
    GetRecommendList() {
      var that = this;
      var params = {
        CustomerID: that.customerID,
        PageNum: that.recommendListPagination.Page,
      };
      API.CommissionList(params).then((res) => {
        if (res.StateCode == 200) {
          that.recommendList = res.List;
          that.recommendListPagination.Total = res.Total;
        } else {
          that.$message.error(res.Message);
        }
      });
    },
    //佣金记录弹窗
    Setcommission(row) {
      this.commissionID = row.ID;
      this.Getcommission();
      this.commissionListPagination.Page = 1;
      this.commissionDialog = true;
    },
    //获取佣金记录
    Getcommission() {
      var that = this;
      var params = {
        CustomerID: that.commissionID,
        PageNum: that.commissionListPagination.Page,
      };
      API.recommendCommissionList(params).then((res) => {
        if (res.StateCode == 200) {
          that.commissionData = res.List;
          that.commissionListPagination.Total = res.Total;
        } else {
          that.$message.error(res.Message);
        }
      });
    },
    // 添加减重计划弹窗
    AddNurse() {
      this.$refs.NurseHealth.ruleForm = {
        PlanName: "",
        planDetailedOutForms: [
          {
            dataTime: [],
            Remark: "",
          },
        ],
      };
      this.$refs.NurseHealth.isAddNur = true;
      this.$refs.NurseHealth.NurVisible = true;
    },
    // 顾客
    saleCustomerData: function (queryString, cb) {
      var that = this;
      that.loading = true;
      var params = {
        Name: queryString ? queryString : "",
      };
      API.getSaleCustomer(params)
        .then((res) => {
          if (res.StateCode == 200) {
            cb(res.Data);
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    //顾客详情弹窗
    customerDetailClick: function () {
      var that = this;
      that.tabPane = "0";
      that.clearnRecordForm();
      // 请求顾客档案 赋值Record
      that.GetRecord();
      that.dialogDetail = true;
    },
    //获取档案信息
    GetRecord() {
      var that = this;
      API.getCustomerBasicsArchives({ CustomerID: that.customerID }).then(
        (res) => {
          console.log(res)
          if (res.StateCode == 200) {
            that.Record = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        }
      );
    },
    handleCustomerSelect(item) {
      console.log(item)
      if (item.ID != undefined) {
        this.customerDetail = Object.assign({}, item);
        const EntityID = JSON.parse(
          localStorage.getItem("access-user")
        ).EntityID;
        this.$refs.sellComponents.IsReturnedCommissionToo = null;
        this.customerEntityBool = EntityID == item.EntityID ? true : false;
        this.customerID = item.ID;
        this.customerFullName = item.Name;
        this.customerPhoneNumber = item.PhoneNumber;
        let code = item.Code?item.Code:''
        this.customerName = item.Name + "【" + code + "】";
        this.customerChange();
      } else {
        if (/^1[3456789]\d{9}$/.test(this.customerName)) {
          this.addCustomerPhoneNumber = this.customerName;
        }
        this.addNewCustomer();
      }
    },
    removeCustomer() {
      this.customerID = null;
      this.customerFullName = "";
      this.customerPhoneNumber = "";
      this.customerName = "";
      this.customerChange();
    },
    // 会员更改
    // customerChange: function () {

    // },
    // 会员更改
    customerChange: function () {
      var that = this;
      switch (that.typeIndex) {
        case 0:
          {
            //销售
            if (that.customerID != null) {
              that.$nextTick(() => {
                // that.$refs.sellComponents.customerID = that.customerID;
                that.$refs.sellComponents.savingCardAllGoodsData();
                that.$refs.sellComponents.savingCardSomeGoodsData();
              });
            } else {
              that.$refs.sellComponents.savingCardAllGoods = [];
              that.$refs.sellComponents.savingCardSomeGoods = [];
              that.$refs.sellComponents.deductionAllReset();
              that.$refs.sellComponents.payAmountData();
            }
          }
          break;

        case 1:
          {
            //消耗
            if (that.customerID != null) {
              that.$nextTick(() => {
                // that.$refs.consumeComponents.customerID = that.customerID;
                that.$refs.consumeComponents.changMemberOrType();
              });
            } else {
              that.$refs.consumeComponents.clearConsumeNetWorkData();
            }
          }

          break;

        case 2:
          {
            //补尾款
            if (that.customerID != null) {
              // that.$refs.workOffArrearsComponents.customerID = that.customerID;
              that.$nextTick(() => {
                that.$refs.workOffArrearsComponents.changeWorkOffArrear();
              });
            } else {
              that.$refs.workOffArrearsComponents.clearConsumeNetWorkData();
            }
          }
          break;
      }
    },
    //补单更改
    isSupplementChange: function () {
      var that = this;
      if (!that.IsSupplement) {
        that.dateChange();
      } else {
        that.BillDate = null;
      }
    },
    // 时间更改
    dateChange: function () {
      var that = this;
      //销售
      that.$refs.sellComponents.isSupplement = that.IsSupplement;
      that.$refs.sellComponents.billDate = that.BillDate;
      if (that.BillDate != null) {
        that.$refs.sellComponents.goodsDataInit();
        that.$refs.sellComponents.goodsData();
        that.$refs.sellComponents.generalCardData();
        that.$refs.sellComponents.timeCardData();
        that.$refs.sellComponents.savingCardData();
        that.$refs.sellComponents.packageCardData();
        that.$refs.sellComponents.clearDateCardItem();

        //消耗
        that.$refs.consumeComponents.isSupplement = that.IsSupplement;
        that.$refs.sellComponents.billDate = that.BillDate;
        that.$refs.consumeComponents.changMemberOrType();
        that.$refs.consumeComponents.clearConsumeSelectItem();
        //补尾款
        if (that.customerID != null) {
          that.$refs.workOffArrearsComponents.customerID = that.customerID;
          that.$refs.workOffArrearsComponents.billDate = that.BillDate;
          that.$refs.workOffArrearsComponents.changeWorkOffArrear();
        }
      }
    },
    /**  切换 开单销售  */
    typeChange: function (index) {
      var that = this;
      that.typeIndex = index;
      switch (index) {
        case 0: //开单
          if (that.customerID != null) {
            that.$refs.agileSellComponents.changMemberOrType();
            that.$refs.agileSellComponents.SellPermission = that.SellPermission;
          }
          break;
        case 1: //销售
          if (that.customerID != null) {
            that.$refs.sellComponents.deductionAllReset();
            that.$refs.sellComponents.payAmountData();
            that.$refs.sellComponents.savingCardAllGoodsData();
            that.$refs.sellComponents.savingCardSomeGoodsData();
            that.$refs.sellComponents.SellPermission = that.SellPermission;
          }
          break;
        case 2: //消耗
          // that.$refs.consumeComponents.customerID = that.customerID;
          that.$refs.consumeComponents.TreatPermission = that.TreatPermission;
          that.$refs.consumeComponents.changMemberOrType();
          break;
        case 3: //补尾款
          that.$refs.workOffArrearsComponents.customerID = that.customerID;
          that.$refs.workOffArrearsComponents.changeWorkOffArrear();
          break;
      }
    },
    //客户所属顾问
    employeeData: function () {
      var that = this;
      APICustomer.getConsultant()
        .then((res) => {
          if (res.StateCode == 200) {
            that.employee = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 顾客来源
    CustomerSourceData: function () {
      var that = this;
      var params = {
        Name: "",
        Active: true,
      };
      APICustomerSource.getCustomerSource(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.customerSource = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 顾客等级
    CustomerLevelData: function () {
      var that = this;
      var params = {
        Name: "",
        Active: true,
      };
      APICustomerLevel.getCustomerLevel(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.customerLevel = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    //性别状态显示转换
    formatGender: function (row) {
      return row.Gender == "1" ? "男" : "女";
    },
    // 新增顾客
    addNewCustomer: function () {
      var that = this;
      that.customer = {
        Name: "",
        PhoneNumber: that.addCustomerPhoneNumber,
        Gender: "2",
        CustomerSourceID: null,
        EmployeeID: [],
        CustomerLevelID: "",
        Code: "",
        Birthday: "",
      };
      that.clearnRecordForm();
      that.dialogVisibleActive = "1";
      that.CustomerNamePaginations.page = 1;
      that.customerbillList = [];
      that.isAddCustom = true;
      that.employeeData();
    },
    //清除档案信息暂存内容
    clearnRecordForm() {
      this.recordForm = {
        Age: "",
        Occupation: "",
        Thigh: "",
        Shank: "",
        UmbilicalDimension: "",
        Arm: "",
        IsPhotograph: false,
        ImproveProblem: [],
        Menses: "",
        Chest: [],
        ShoulderNeck: [],
        SleepTime: "",
        SleepQuality: "",
        Defecation: "",
        WaistCondition: "",
        IceCold: "",
        FoodPreference: [],
        WorkEnvironment: "",
        TypeA: "",
        Health: "",
        TypeH: "",
        TypeV: "",
        DietAdvice: "",
        SuggestionsConditioning: [],
      };
    },
    // 保存新增客户
    submitCustomer: function () {
      var that = this;
      this.$refs.customer.validate((valid) => {
        if (valid) {
          that.modalLoading = true;
          let params = Object.assign(that.recordForm, that.customer);
          APICustomer.createCustomer(params)
            .then(function (res) {
              if (res.StateCode === 200) {
                that.$message.success({
                  message: "新增成功",
                  duration: 2000,
                });
                that.customerID = res.Data.ID;
                that.customerFullName = res.Data.Name;
                that.customerPhoneNumber = res.Data.PhoneNumber;
                let code = res.Data.Code?res.Data.Code:''
                that.customerName = res.Data.Name + "【" + code + "】";
                that.customerDetail = res.Data
                that.getNewCustomer(res.Data.ID)
                // that.handleCustomerSelect(res.Data);
                that.$refs["customer"].resetFields();
                that.isAddCustom = false;
              } else {
                that.$message.error({
                  message: res.Message,
                  duration: 2000,
                });
              }
            })
            .finally(function () {
              that.modalLoading = false;
            });
        }
      });
    },
    getNewCustomer(ID){
      var params = {
        CustomerID:ID
      }
      API.getSaleCustomer(params).then(res => {
        if(res.StateCode == 200){
          this.handleCustomerSelect(res.Data[0])
        }
      })
  },
  },

  mounted() {
    var that = this;
    that.customerID = this.$route.params.customerID
    that.IsReturnedCommissionToo = this.$route.params.IsReturnedCommission
    that.SellPermission.ModifyPrices_SaleGeneralCard = permission.permission(
      that.$route.meta.Permission,
      "iBeauty-Order-Bill-SaleGeneralCard-ModifyPrices"
    );
    that.customTagLibrary = permission.permission(
      that.$route.meta.Permission,
      "iBeauty-Order-Bill-CustomTagLibrary"
    );
    that.SellPermission.ModifyPrices_SalePackageCard = permission.permission(
      that.$route.meta.Permission,
      "iBeauty-Order-Bill-SalePackageCard-ModifyPrices"
    );
    that.SellPermission.ModifyPrices_SaleProduct = permission.permission(
      that.$route.meta.Permission,
      "iBeauty-Order-Bill-SaleProduct-ModifyPrices"
    );
    that.SellPermission.ModifyPrices_SaleProject = permission.permission(
      that.$route.meta.Permission,
      "iBeauty-Order-Bill-SaleProject-ModifyPrices"
    );

    that.SellPermission.ModifyPrices_SaleTimeCard = permission.permission(
      that.$route.meta.Permission,
      "iBeauty-Order-Bill-SaleTimeCard-ModifyPrices"
    );
    that.TreatPermission.ModifyPrices_TreatSavingCard = permission.permission(
      that.$route.meta.Permission,
      "iBeauty-Order-Bill-TreatSavingCardt-ModifyPrices"
    );
    that.customInfoEdit = permission.permission(
      that.$route.meta.Permission,
      "iBeauty-Order-Bill-ModifyNotBelongEntityCustomer"
    );

    // 是否显示开单
    that.isAgileSell = permission.permission(
      that.$route.meta.Permission,
      "iBeauty-Order-Bill-AgileSell"
    );
    // 是否显示补单
    that.isReplacementOrder = permission.permission(
      that.$route.meta.Permission,
      "iBeauty-Order-Bill-ReplacementOrder"
    );

    that.typeIndex = that.isAgileSell ? 1 : 0;

    that.employeeData();
    that.CustomerSourceData();
    that.CustomerLevelData();
    // that.saleCustomerData();
  },
};
</script>

<style lang="scss">
.order_create {
  padding: 0;
  height: 100%;
}

.customer-autocomplete {
  li {
    line-height: normal;
    padding: 7px;

    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .info {
      font-size: 12px;
      color: #b4b4b4;
    }
    .highlighted .info {
      color: #ddd;
    }
  }
}
.el-input-group__append {
  // position: relative;
  .detailBtn {
    left: -80px;
    color: #409eff !important;
  }
}
.CustomerSourceID {
  .el-form-item__content {
    margin-left: 20px !important;
    margin-right: 15px !important;
  }
}
.AddNurse {
  position: absolute;
  right: 20px;
  top: 3px;
  padding: 9px 20px;
  z-index: 99;
}
</style>